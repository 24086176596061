if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.hotelResult = {
  init__hotel__result: function () {
    // Filter
    $(document).on('click', '[data-filter-step]', function () {
      const self = $(this);
      const target = self.data('target');
      const location = self.data('location');
      const label = self.data('filter-step');
      const value = self.data('id');
      const modalContent = self
        .closest('.modal--more-filter')
        .find('.modal-content');

      const dropdownCountry = $('#dropdownCountry');
      const dropdownCity = $('#dropdownCity');

      if (target) {
        $(target).addClass('opened');
        modalContent.addClass('move-left');
      } else {
        // Close all steps
        $('[data-step]').removeClass('opened');
        modalContent.removeClass('move-left');
      }
      // Update label
      if (location && $('[data-label=' + location + ']').length) {
        $('[data-label=' + location + ']').html(label);
      }

      if (location == 'country') {
        dropdownCountry.find('.dropdown-label').html(label);
      } else {
        dropdownCity.find('.dropdown-label').html(label);
      }

      //update value
      if (location == 'country') {
        $('#modal_selected_country').val(value);
      }
      if (location == 'city') {
        $('#modal_selected_city').val(value);
      }

      //check to load city
      if (location == 'country') {
        $('#modal_selected_city').val('');
        const ajaxCitites = $.ajax({
          type: 'post',
          dataType: 'json',
          url: myAjax.ajaxurl,
          data: {
            action: 'harper_get_cities',
            security: myAjax.check_nonce,
            country_id: value,
          },
        });

        ajaxCitites
          .done(function (response) {
            let data = response.data;
            if (data && data.length) {
              let html = '';
              let htmlDropdown = '';

              data.forEach((item) => {
                html += `<li class="item" data-location='city' data-id="${item.term_id}" data-filter-step='${item.name}'>${item.name}</li>`;

                htmlDropdown += `<button class="dropdown-item" data-location='city' data-id="${item.term_id}" data-filter-step='${item.name}'>${item.name}</button>`;
              });

              $('#modal_selected_city').val('');
              $('.filter-location__city').html(html);
              $('[data-label=city]').html('Select City');

              dropdownCity.find('.dropdown-label').html('Select City');
              dropdownCity
                .closest('.dropdown')
                .find('.dropdown-inner')
                .html(htmlDropdown);
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            // cities.find('.loading').addClass('d-none');
            console.log(textStatus + ': ' + errorThrown);
          });
      }
      reloadHotel();
    });

    $('.desktop_filter_category_list input[type=checkbox]').on(
      'click',
      function () {
        reloadHotel();
      },
    );
    $('.desktop_filter_rating_list input[type=checkbox]').on(
      'click',
      function () {
        reloadHotel();
      },
    );

    function reloadHotel() {
      const country = $('#modal_selected_country').val();
      const city = $('#modal_selected_city').val();
      let categories = [];
      let rating = [];

      $('.desktop_filter_category_list input[type=checkbox]').each(function (
        idx,
        item,
      ) {
        if (item.checked == true) {
          categories.push(item.value);
        }
      });
      $('.desktop_filter_rating_list input[type=checkbox]').each(function (
        idx,
        item,
      ) {
        if (item.checked == true) {
          rating.push(item.value);
        }
      });
      // let rating_input_value = $('#rating_input_desktop').val();
      // if (rating_input_value != '') {
      //   rating.push($('#rating_input_desktop').val());
      // }
      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'harper_hotel_modal_filter',
          security: myAjax.check_nonce,
          categories: categories,
          rating: rating,
          city_id: city,
          country_id: country,
        },
        success: function success(response) {
          if (response.code == 1) {
            var data = response.data;
            $('.hotels .hotels__inner').html(data);
            // $('#moreFilterModal').modal('hide');
          } else {}
        },
      });
    }
    $('[data-back-step]').on('click', function () {
      const self = $(this);
      const step = self.closest('[data-step]');
      const modalContent = self
        .closest('.modal--more-filter')
        .find('.modal-content');

      step.removeClass('opened');
      modalContent.removeClass('move-left');
    });

    // Reset
    $('#moreFilterModal').on('hidden.bs.modal', function () {
      const self = $(this);

      self.find('.opened').removeClass('opened');
      self.find('.move-left').removeClass('.move-left');
    });

    $('.dropdown-item--rating').on('click', function () {
      const self = $(this);
      const value = self.data('value');
      $('#rating_input_desktop').val(value);
      reloadHotel();
      // Ipad, Desktop

      if (value) {
        self
          .closest('.dropdown')
          .find('.dropdown-label .star__mask')
          .attr('style', 'width: ' + value * 20 + '%');

        self.closest('.dropdown').removeClass('is-all');
      } else {
        self.closest('.dropdown').addClass('is-all');
      }

      // Trigger checked checkbox on mobile
    });

    $('.filter .filter__back').on('click', function (e) {
      e.preventDefault();
      window.history.back();
    });
  },
  init__hotel__modal_filter: function () {
    $('#btnApplyFilter').on('click', function () {
      const country = $('#modal_selected_country').val();
      const city = $('#modal_selected_city').val();
      let categories = [];
      let rating = [];
      let _rating = [];
      $('.modal_filter_category_list input[type=checkbox]').each(function (
        idx,
        item,
      ) {
        if (item.checked == true) {
          categories.push(item.value);
        }
      });
      $('.modal_filter_rating_list input[type=checkbox]').each(function (
        idx,
        item,
      ) {
        if (item.checked == true) {
          rating.push(item.value);
        }
      });

      $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'harper_hotel_modal_filter',
          security: myAjax.check_nonce,
          categories: categories,
          rating: rating,
          city_id: city,
          country_id: country,
        },
        success: function success(response) {
          if (response.code == 1) {
            var data = response.data;
            $('.hotels .hotels__inner').html(data);
            $('#moreFilterModal').modal('hide');
          } else {}
        },
      });
    });

    $('.dropdown-item--rating').on('click', function () {
      const self = $(this);
      const value = self.data('value');
      $('#rating_input_desktop').val(value);

      // Ipad, Desktop
      self
        .closest('.dropdown')
        .find('.dropdown-label .star__mask')
        .attr('style', 'width: ' + value * 20 + '%');

      // Trigger checked checkbox on mobile
    });
  },
  init__hotel__filter: function () {
    var selected_category = [];

    function getSelectedCategory() {
      selected_category = [];
      $('.category-checkboxs .item-check input[type=checkbox]').each(function (
        idx,
        item,
      ) {
        if (item.checked == true) {
          selected_category.push(item.value);
        }
      });
    }

    function updateCategoryCheckboxStatus() {
      if (selected_category.length == 0) {
        $('#categoryAll').prop('checked', true);
      } else {
        $('#categoryAll').prop('checked', false);
      }
    }

    $('#categoryAll').on('change', function () {
      if ($(this).prop('checked') == true) {
        selected_category = [];
        $('#categoryAll').prop('checked', true);
        $('.category-checkboxs .item-check input[type=checkbox]').each(
          function (idx, item) {
            $(this).prop('checked', false);
          },
        );
      }
    });

    $('.category-checkboxs .item-check input[type=checkbox]').on(
      'change',
      function () {
        let self = $(this);
        getSelectedCategory();
        updateCategoryCheckboxStatus();
        var city_id = $('#city_id').val();
        //load data
        console.log(selected_category);
        $.ajax({
          type: 'post',
          dataType: 'json',
          url: myAjax.ajaxurl,
          data: {
            action: 'harper_hotel_filter',
            security: myAjax.check_nonce,
            categories: selected_category.length ? selected_category : [],
            city_id: city_id,
          },
          success: function success(response) {
            if (response.code == 1) {
              var data = response.data;
              $('.hotels .hotels__inner').html(data);
            } else {}
          },
        });
      },
    );
  },
};
