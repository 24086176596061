if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.home = {
  init__home: function () {
    this.init__recommened__slider();
    this.init__topcities__slider();
    // this.init__logos__slider();
    this.init__resize();
  },
  init__recommened__slider: function () {
    const w = $(window).width();
    if (w < 1201) {
      $('.recommended-place__slider').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        arrows: false,
      });
    }
  },
  init__topcities__slider: function () {
    const w = $(window).width();
    if (w < 1025) {
      $('.top-cities__slider').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        arrows: false,
      });
    }
  },
  init__logos__slider: function () {
    const w = $(window).width();
    if (w < 1025) {
      $('.logos__slider').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        arrows: false,
      });
    }
  },
  init__resize: function () {
    const that = this;
    $(window).on('resize', function () {
      const self = $(this);
      const w = self.width();
      const recommendedSlider = $('.recommended-place__slider');
      const topCitiesSlider = $('.top-cities__slider');
      const logosSlider = $('.logos__slider');

      if (w > 1200) {
        if (
          recommendedSlider.length &&
          recommendedSlider.hasClass('slick-initialized')
        ) {
          recommendedSlider.slick('unslick');
        }

        if (
          topCitiesSlider.length &&
          topCitiesSlider.hasClass('slick-initialized')
        ) {
          topCitiesSlider.slick('unslick');
        }

        if (logosSlider.length && logosSlider.hasClass('slick-initialized')) {
          logosSlider.slick('unslick');
        }
      } else {
        if (
          recommendedSlider.length &&
          !recommendedSlider.hasClass('slick-initialized')
        ) {
          that.init__recommened__slider();
        }

        if (
          topCitiesSlider.length &&
          !topCitiesSlider.hasClass('slick-initialized')
        ) {
          that.init__topcities__slider();
        }

        // if (logosSlider.length && !logosSlider.hasClass('slick-initialized')) {
        //   that.init__logos__slider();
        // }
      }
    });
  },
};
