// Import css
import './scss/index.scss';

if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}

import './js/home';
import './js/hotel-detail';
import './js/hotel-result';
import './js/about-us';

const clickOrTouch = 'ontouchend' in window ? 'touchend' : 'click';

window.Steenify.common = {
  init: function () {
    Steenify.common.init__header();
    Steenify.common.init__search();
    Steenify.common.init__search_text();
    Steenify.common.init__page();
    Steenify.common.init__scroll();
  },
  init__page: function () {
    const view = $('.steenify__page').data('view');
    switch (view) {
      case 'home':
        Steenify.home.init__home();
        break;
      case 'hotel-detail':
        Steenify.hotelDetail.init__hotel__detail();
        break;
      case 'hotel-result':
        Steenify.hotelResult.init__hotel__result();
        Steenify.hotelResult.init__hotel__filter();
        Steenify.hotelResult.init__hotel__modal_filter();
        break;
      case 'about-us':
        Steenify.aboutUs.init__about__us();
        break;
      default:
        console.log(view);
    }
  },
  init__header: function () {
    $('.header .hamburger').on('click', function () {
      const self = $(this);
      const mainMenu = self.closest('.header').find('.main-menu');

      self.toggleClass('clicked');
      mainMenu.stop().fadeToggle();
    });

    $('.header .hamburger--clicked').on('click', function () {
      const self = $(this);

      self.closest('.header').find('.hamburger').trigge('click');
    });

    $('.back-to-top').on('click', function () {
      $('html, body').stop().animate(
        {
          scrollTop: 0,
        },
        '1500',
      );
    });

    $('.header .main-menu .item__link--search').on('click', function (e) {
      e.preventDefault();
      const self = $(this);
      const header = self.closest('.header');
      const searchMask = header.find('.search__mask');

      $('.header').addClass('search-active');

      searchMask.trigger('click');
    });

    $('.hotel-search-page-input').on('click', function (e) {
      e.preventDefault();
      const self = $(this);
      const header = $('.header');
      const searchMask = header.find('.search__mask');

      $('.header').addClass('search-active');

      searchMask.trigger('click');
    });

    $('.footer .input-group').on('click', function () {
      const header = $('.header');
      const searchMask = header.find('.search__mask');

      searchMask.trigger('click');
    });
  },
  init__search_text: function () {
    function delay(callback, ms) {
      var timer = 0;
      return function () {
        var context = this,
          args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, ms || 0);
      };
    }

    $('#header_search_input').keyup(
      delay(function (e) {
        const countries = $('#countries');
        const cities = $('#cities');

        const resultCountry = $('#resultCountry');
        const resultSearchText = $('#resultSearchText');
        const list = resultSearchText.find('.list');
        var keyword = this.value;

        countries.hasClass('move-left') && countries.removeClass('move-left');
        cities.hasClass('active') && cities.removeClass('active');

        list.html('');
        list.removeClass('flex-row align-items-start');
        resultSearchText.find('.results__title').addClass('d-none');
        resultSearchText.find('.results__title__label').html('');

        if (keyword != '') {
          resultSearchText.removeClass('d-none');
          resultCountry.addClass('d-none');
          resultSearchText.find('.loading').removeClass('d-none');

          $.ajax({
            type: 'post',
            dataType: 'json',
            url: myAjax.ajaxurl,
            data: {
              action: 'harper_hotel_search',
              keyword: keyword,
              security: myAjax.check_nonce,
            },
            success: function success(response) {
              resultSearchText.find('.loading').addClass('d-none');

              if (response.code == 1) {
                var data = response.data;
                if (data && data.length) {
                  let rData = [...data];
                  let classNameLayout = 'item--suggest';

                  // Memo: Special case
                  if (data.length === 1 && data[0].cities && data[0].cities.length) {
                    const c = data[0].cities;
                    // Memo: No need render `country`
                    // We will render all of cities inside
                    classNameLayout = '';
                    rData = [...c];
                    list.addClass('flex-row align-items-start');

                    // Replace title
                    resultSearchText
                      .find('.results__title__label')
                      .html(data[0].name);
                    resultSearchText
                      .find('.results__title')
                      .removeClass('d-none');
                  }

                  let html = '';
                  rData.forEach((item) => {
                    var name = item.name;
                    if (item.parent_term != undefined) {
                      name += ', ' + item.parent_term.name;
                    }

                    if (parseFloat(item.parent) === 0) {
                      html =
                        html +
                        '<li class="item ' +
                        classNameLayout +
                        '"  data-countryname="' +
                        name +
                        '" data-country="' +
                        item.term_id +
                        '"><svg class="icon" aria-hidden="true" width="14" height="14" title=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_search_1"></use></svg>' +
                        name +
                        '</li>';
                    } else {
                      html =
                        html +
                        '<li class="item ' +
                        classNameLayout +
                        '" data-city="' +
                        item.term_id +
                        '"><a href="/hotel-search?city=' +
                        item.term_id +
                        '" class="item__link"> <svg class="icon" aria-hidden="true" width="14" height="14" title=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_search_1"></use></svg>' +
                        name +
                        '</a></li>';
                    }
                  });

                  list.html(html);
                }
              } else {
                list.html(
                  '<li class="item item--full-width">Sorry, no result found</li>',
                );
              }
            },
          });
        } else {
          if (resultCountry.hasClass('d-none')) {
            resultCountry.removeClass('d-none');
            resultCountry.find('.loading').addClass('d-none');
          }

          resultSearchText.addClass('d-none');
        }
      }, 500),
    );

    $('#resultSearchText')
      .find('.results__back')
      .on('click', function () {
        $('#header_search_input').val('');
        const resultCountry = $('#resultCountry');
        const resultSearchText = $('#resultSearchText');

        if (resultCountry.hasClass('d-none')) {
          resultCountry.removeClass('d-none');
          resultCountry.find('.loading').addClass('d-none');
        }

        resultSearchText.addClass('d-none');
      });

    $('#mainmenu_search_input').keyup(
      delay(function (e) {
        const self = $(this);
        const list = self.closest('.search-suggest').find('.list');
        const keyword = this.value;

        if (keyword != '') {
          $.ajax({
            type: 'post',
            dataType: 'json',
            url: myAjax.ajaxurl,
            data: {
              action: 'harper_hotel_search',
              keyword: keyword,
              security: myAjax.check_nonce,
            },
            success: function success(response) {
              if (response.code == 1) {
                const data = response.data;
                if (data && data.length) {
                  let html = '';
                  data.forEach((item) => {
                    var name = item.name;
                    if (item.parent_term != undefined) {
                      name += ', ' + item.parent_term.name;
                    }

                    if (parseFloat(item.parent) === 0) {
                      html =
                        html +
                        '<li class="item item--suggest"  data-countryname="' +
                        name +
                        '" data-country="' +
                        item.term_id +
                        '"><svg class="icon" aria-hidden="true" width="14" height="14" title=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_search_1"></use></svg>' +
                        name +
                        '</li>';
                    } else {
                      html =
                        html +
                        '<li class="item item--suggest" data-city="' +
                        item.term_id +
                        '"><a href="/hotel-search?city=' +
                        item.term_id +
                        '" class="item__link"> <svg class="icon" aria-hidden="true" width="14" height="14" title=""><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_search_1"></use></svg>' +
                        name +
                        '</a></li>';
                    }
                  });

                  list.html(html);
                }
              } else {
                list.html(
                  '<li class="item item--full-width">Sorry, no result found</li>',
                );
              }
            },
          });
        } else {
        }
      }, 500),
    );
  },
  init__search: function () {
    function focusAndOpenKeyboard(el, timeout) {
      if (!timeout) {
        timeout = 100;
      }
      if (el) {
        // Align temp input element approximately where the input element is
        // so the cursor doesn't jump around
        var __tempEl__ = document.createElement('input');
        __tempEl__.style.position = 'absolute';
        __tempEl__.style.top = el.offsetTop + 7 + 'px';
        __tempEl__.style.left = el.offsetLeft + 'px';
        __tempEl__.style.height = 0;
        __tempEl__.style.opacity = 0;
        // Put this temp element as a child of the page <body> and focus on it
        document.body.appendChild(__tempEl__);
        __tempEl__.focus();

        // The keyboard is open. Now do a delayed focus on the target element
        setTimeout(function () {
          el.focus();
          el.click();
          // Remove the temp element
          document.body.removeChild(__tempEl__);
        }, timeout);
      }
    }

    // Toggle search
    $('.search .search__mask').on('click', function () {
      const self = $(this);
      const search = self.closest('.search');
      const searchForm = search.find('.search__form');
      var myElement = document.getElementById('header_search_input');
      var modalFadeInDuration = 300;
      focusAndOpenKeyboard(myElement, modalFadeInDuration);
      searchForm.stop().fadeIn(function () {
        // Load Countries
        const countries = $('#countries');
        const list = countries.find('#resultCountry .list');

        list.html('');

        const ajaxCountries = $.ajax({
          type: 'post',
          dataType: 'json',
          url: myAjax.ajaxurl,
          data: {
            action: 'harper_get_countries',
            security: myAjax.check_nonce,
          },
        });

        countries.find('.loading').removeClass('d-none');

        ajaxCountries
          .done(function (response) {
            let data = response.data;

            countries.find('.loading').addClass('d-none');

            if (data && data.length) {
              data.sort(function (a, b) {
                const textA = a.name ? a.name.toUpperCase() : '';
                const textB = b.name ? b.name.toUpperCase() : '';
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });

              let html = '';
              data.forEach((item) => {
                html =
                  html +
                  '<li class="item" data-countryname="' +
                  item.name +
                  '" data-country="' +
                  item.term_id +
                  '">' +
                  item.name +
                  '</li>';
              });

              list.html(html);

              setTimeout(() => {
                // calc height of list
                const _w = $(window).width();
                list.css({
                  height:
                    _w > 767
                      ? Math.round(list.find('.item').length / 4) * 42 + 15 ||
                      'auto'
                      : 'auto',
                });
              }, 500);

              // $('#header_search_input').focus().blur().focus();
              // $('#header_search_input').click();
              // $('#header_search_input').focus();
            } else {
              list.html(
                '<li class="item item--full-width">Sorry, no result found</li>',
              );
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            countries.find('.loading').addClass('d-none');
            console.log(textStatus + ': ' + errorThrown);
          });
      });
    });

    $('.search .search__close').on('click', function () {
      const self = $(this);
      const search = self.closest('.search');
      const searchForm = search.find('.search__form');
      const searchStep = self.closest('.search__form').find('.search__step');

      searchForm.find('.ele-animate').removeClass('animated');
      searchForm.stop().fadeOut();

      // Reset search step
      searchStep.removeClass('move-left');
      searchStep.removeClass('active');

      $('.header').removeClass('search-active');
    });

    $('.search .results__back').on('click', function () {
      const self = $(this);
      const search = self.closest('.search');
      const searchStep = self.closest('.search__form').find('.search__step');

      searchStep.removeClass('move-left');
      searchStep.removeClass('active');
    });

    $(document).on('click', '[data-country]', function () {
      const self = $(this);
      const country_id = self.data('country');
      const countryName = self.data('countryname');
      const stepClosest = self.closest('[data-step]');
      const stepTarget = stepClosest.data('target');

      // Load Cities of country
      const cities = $('#cities');
      cities.find('#countryName').html(countryName);
      const list = cities.find('.list');
      const ajaxCitites = $.ajax({
        type: 'post',
        dataType: 'json',
        url: myAjax.ajaxurl,
        data: {
          action: 'harper_get_cities',
          security: myAjax.check_nonce,
          country_id: country_id,
        },
      });

      list.html('');
      cities.find('.loading').removeClass('d-none');

      ajaxCitites
        .done(function (response) {
          let data = response.data;

          cities.find('.loading').addClass('d-none');

          if (data && data.length) {
            let html = '';
            data.sort(function (a, b) {
              const textA = a.name ? a.name.toUpperCase() : '';
              const textB = b.name ? b.name.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });

            data.forEach((item) => {
              html =
                html +
                '<li class="item" data-city="' +
                item.term_id +
                '"><a href="/hotel-search?city=' +
                item.term_id +
                '" class="item__link">' +
                item.name +
                '</a></li>';
            });

            list.html(html);
            setTimeout(() => {
              const _w = $(window).width();
              list.css({
                height:
                  _w > 767
                    ? Math.ceil(list.find('.item').length / 4) * 42 + 15 ||
                    'auto'
                    : 'auto',
              });
            }, 500);
            stepClosest.addClass('move-left');
            $(stepTarget).addClass('active');
          } else {
            list.html(
              '<li class="item item--full-width">Sorry, no result found</li>',
            );
          }
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          cities.find('.loading').addClass('d-none');
          console.log(textStatus + ': ' + errorThrown);
        });
    });

    $(document).on('click', '[data-back]', function () {
      const self = $(this);
      const stepClosest = self.closest('[data-step]');

      $('.search__step').removeClass('move-left');
      stepClosest.removeClass('active');
    });

    // Trigger click search
    $('.trigger-search').on('click', function () {
      $('.search .search__mask').trigger('click');
    });

    // Resize
    $(window).on('resize', function () {
      const _w = $(window).width();
      const countriesList = $('#countries .list');
      const citiesList = $('#cities .list');
      if (_w > 767) {
        countriesList.css({
          height: Math.ceil(countriesList.find('.item').length / 4) * 42,
        });

        citiesList.css({
          height: Math.ceil(citiesList.find('.item').length / 4) * 42,
        });
      } else {
        countriesList.css({
          height: 'auto',
        });

        citiesList.css({
          height: 'auto',
        });
      }
    });
  },
  init__scroll: function () {
    $(window).scroll(function () {
      const self = $(this);
      const scroll = self.scrollTop();
      const banner = $('.banner');
      const header = $('.header');

      if (banner.length) {
        if (scroll > 120) {
          header.addClass('header--bg-dark header--sticky');
        } else {
          header.removeClass('header--bg-dark header--sticky');
        }
      }
    });
  },
};

//***************************************
//      Main program
//***************************************
$(document).ready(function () {
  Steenify.common.init();
});
