if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.hotelDetail = {
  init__hotel__detail: function () {
    this.init__gallery__pictures__slider();
    this.init__featured__logos__slider();
    this.init__reviews__slider();
    // this.init__featured__hotels__slider();

    $('.share_button').on('click', function () {
      $('.a2a_kit').css('display', 'block');
      $('.a2a_kit').css('opacity', 1);
    });

    if (!$('.featured').length) {
      $('.location').first().addClass('location--without-line');
    }
  },
  init__gallery__pictures__slider: function () {
    $('.gallery-pictures').slick({
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
    });
  },
  init__featured__logos__slider: function () {
    $('.featured__logos').slick({
      dots: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            variableWidth: true,
            autoplay: false,
            infinite: false,
          },
        },
      ],
    });
  },
  init__reviews__slider: function () {
    const reviews = $('.reviews');
    if (reviews.find('.item').length) {
      if (reviews.find('.item').length === 1) {
        reviews.addClass('hide-dots');
      }

      reviews.slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
      });
    }
  },
  init__featured__hotels__slider: function () {
    const featuredHotels = $('.featured-hotels__slider');
    const w = $(window).width();

    if (featuredHotels.length && w < 767) {
      featuredHotels.slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
      });
    }

    $(window).on('resize', function () {
      const windowWidth = $(this).width();

      if (windowWidth < 767) {
        if (!featuredHotels.hasClass('slick-initialized')) {
          // featuredHotels.slick({
          //   arrows: false,
          //   dots: true,
          //   autoplay: true,
          //   autoplaySpeed: 3000,
          // });
        }
      } else {
        if (featuredHotels.hasClass('slick-initialized')) {
          featuredHotels.slick('unslick');
        }
      }
    });
  },
};
