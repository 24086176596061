if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify.aboutUs = {
  init__about__us: function () {
    this.init__featured__hotels__slider();
  },
  init__featured__hotels__slider: function () {
    const featuredHotels = $('.featured-hotels__slider');
    const w = $(window).width();

    if (featuredHotels.length && w < 767) {
      // featuredHotels.slick({
      //   arrows: false,
      //   dots: true,
      // });
    }

    $(window).on('resize', function () {
      const windowWidth = $(this).width();

      if (windowWidth < 767) {
        if (!featuredHotels.hasClass('slick-initialized')) {
          // featuredHotels.slick({
          //   arrows: false,
          //   dots: true,
          // });
        }
      } else {
        if (featuredHotels.hasClass('slick-initialized')) {
          featuredHotels.slick('unslick');
        }
      }
    });
  },
};
